<template>
  <div class="goods-list">
    <template v-for="(item, index) in list" :key="index">
      <van-card
        :price="formatPrice(item.retailPrice)"
        :title="item.productName"
        :thumb="item.thumb"
        :currency="SYSLANG.currency"
        :lazy-load="true"
        @click="openGoods(item.productId)"
      >
	     <template v-slot:tags>
				  <slot name="tags">
					  <div v-if="item.categoryName=='组合系列B' || item.categoryName=='组合系列C' ||  item.categoryName=='组合系列A' ||  item.categoryName=='Fun肆嗨购' " style="margin-top:10px;color: red;">{{item.categoryName}}商品 </div>
					  <div v-if="item.isOpenToBooking==1" style="margin-top:10px;color: red;">排單商品</div>
				  </slot>
		 </template>			  
        <template #bottom>
          <div class="goods-stock">{{ PAGELANG.stock }}: {{ item.stock }}</div>
        </template>
        <template #footer>
          <van-button
            icon="aog iconfont icon-cart"
            color="#cfae6d"
            round
            type="primary"
            size="small"
            class="btn-cart"
            @click.stop="openAttribute(item.productId)"
          />
        </template>
      </van-card>
    </template>

    <GoodsProps
      :showAttr="showAttr"
      :GoodsID="curGoodsID"
      :showBuyButton="false"
      @closeProps="closeProps"
    />
  </div>

</template>

<script>
import { getCurrentInstance } from "vue";
import GoodsProps from "./GoodsProps.vue";

export default {
  name: "GoodsList",
  props: {
    list: {
      type: Array,
      default: new Array(),
    },
    size: {
      type: Number,
      default: 3,
    },
  },
  components: {
    GoodsProps,
  },
  data() {
    return {
      showAttr: false,
      curGoodsID: 0,
    };
  },
  beforeCreate() {
    const root = getCurrentInstance().appContext.config.globalProperties;
    this.PAGELANG = root.LANG.pages.goods;
    this.SYSLANG = root.LANG.system;
    this.UPLOADBASE = root.uploadbase;
  },
  methods: {
    openAttribute(goodsid) {
      console.log("打开商品属性", goodsid);
      this.showAttr = true;
      this.curGoodsID = goodsid;
    },
	formatPrice(price){
	    return (price / 100).toFixed(2)
	},
    openGoods(goodsid) {
      console.log(this.$router);
      this.$router.push({
        path: "/product/detail",
        query: {
          GoodsID: goodsid,
        },
      });
    },
    closeProps(val) {
      console.log("closeProps", val);
      this.showAttr = val;
    },
  },
};
</script>

<style scoped>
@media (max-width: 768px) {
  .pc-goods-list {
    display: none;
  }
  .goods-list {
    --van-card-background: #fff;
    --van-button-small-padding: 0 7px;
  }

  .goods-list >>> .van-card__footer {
    position: absolute;
    bottom: var(--van-padding-xs);
    right: var(--van-padding-md);
  }

  .goods-list >>> .van-card__title {
    font-size: 13px;
  }

  .goods-list .goods-stock {
    color: #777;
  }

  .goods-list >>> .btn-cart {
    width: 32px;
    height: 32px;
  }

  .goods-list >>> .btn-cart .van-icon-aog {
    color: #fff;
  }
}

@media (min-width: 769px) {
  .goods-list {
    display: none;
  }

  .pc-goods-list {
    width: 100%;
  }

  .pc-goods-list .list {
    display: flex;
    width: 100%;
    flex-wrap: wrap;
  }
  .pc-goods-list .list .item {
    width: calc(100vw / 1920 * 280);
    margin-bottom: calc(100vw / 1920 * 30);
    margin-right: calc(100vw / 1920 * 26);
  }
  .pc-goods-list .list .item .link {
    display: block;
  }
  .pc-goods-list .list .item .thumb {
    width: 100%;
    height: calc(100vw / 1920 * 280);
  }
  .pc-goods-list .list .item .title {
    height: calc(100vw / 1920 * 44);
    line-height: calc(100vw / 1920 * 44);
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    font-size: calc(100vw / 1920 * 16);
    color: #000;
  }
  .pc-goods-list .list .item .price {
    height: calc(100vw / 1920 * 28);
    font-size: calc(100vw / 1920 * 24);
    color: var(--van-card-price-color);
  }
  .pc-goods-list .list .item .price::before {
    content: var(--aog-currency);
    font-size: 70%;
  }
  .pc-goods-list .list .item .price::after {
    content: "\e602";
    display: block;
    width: calc(100vw / 1920 * 22);
    line-height: calc(100vw / 1920 * 22);
    margin-top: calc(100vw / 1920 * 3);
    text-align: center;
    font-family: "iconfont";
    font-size: calc(100vw / 1920 * 14);
    color: #fff;
    background-color: var(--van-card-price-color);
    float: right;
    border-radius: 50%;
  }
}
</style>
