<template>
  <div class="m-props">
    <van-popup
      v-model:show="showProp"
      closeable
      safe-area-inset-bottom
      position="bottom"
      style="overflow: initial"
      @close="onClose"
      :lock-scroll="false"
    >
      <div class="goods-attrs">
        <div class="info">
          <van-image
            class="thumb"
            fit="cover"
            position="left"
            :src="curThumb"
          />
          <div class="cur-attrs">
            <span class="price">{{ SYSLANG.currency }}{{ formatPrice(curPrice) }}</span>
            <span class="stock"
              >{{ PAGELANG.stock }} {{ curStock }} {{ PAGELANG.unit }}</span
            >
            <span v-if="selectedAttr.length > 0" class="selected">
              {{ PAGELANG.selected }}:
              <template v-for="(attr, aidx) in selectedAttr" :key="aidx">
                <template v-if="aidx > 0">,</template
                >{{ attr != "" ? attr : SKUProps[aidx].PropName }}
              </template>
            </span>
          </div>
        </div>

        <div class="params" v-for="(attr, aidx) in propboard" :key="aidx">
          <div class="title">{{ attr.name}}</div>
          <div class="options">
            <template v-for="(val, vidx) in attr.values" :key="vidx">
         <!--     <span v-if="val.enabled==true" class="option disabled">{{
                val.name.name
              }}</span> -->
			  
              <span
			    v-if="val.name.enabled==true && val.isShow==true"
                :class="'option' + (val.selected ? ' active' : '')"
                @click="selectOption(aidx, vidx)"
                >{{ val.name.name}}</span
              >
            </template>
          </div>
        </div>

        <div class="buy-count">
          {{ PAGELANG.buycount }}
          <van-stepper
            v-model="buyCount"
            :min="curStock >= 1 ? 1 : curStock"
            :max="curStock"
            integer
            theme="round"
            button-size="22"
          />
        </div>
      </div>

      <div class="btns">
        <div v-if="curStock>0 && isOffLine==false" class="btn add-cart" @click="addToCart">
          {{ PAGELANG.addcart }}  
        </div>
		<div v-if="curStock==0 && isOffLine==false" class="btn add-cart" style="background-color: #eee;">
		   庫存不足無法加入購物車
		</div>
		<div v-if="isOffLine==true" class="btn add-cart" style="background-color: #eee;">
              無法加入購物車
		</div>
<!--        <div v-if="showBuyButton" class="btn buy-now" @click="buyNow">
          {{ PAGELANG.buynow }}
        </div> -->
      </div>
    </van-popup>
  </div>

<!--  <div class="pc-props" :style="'--aog-currency:\'' + SYSLANG.currency + '\';'">
    <div class="params">
      <div class="title">{{ PAGELANG.price }}</div>
      <div class="price">{{ formatPrice(curPrice) }}</div>
    </div>
    <div class="params" v-for="(attr, aidx) in propboard" :key="aidx">
      <div class="title">{{ attr.PropName }}</div>
      <div class="attrs">
        <template v-for="(val, vidx) in attr.options" :key="vidx">
          <span v-if="!val.enabled" class="option disabled">{{
            val.name
          }}</span>
          <span
            v-else
            :class="'option' + (val.selected ? ' active' : '')"
            @click="selectOption(aidx, vidx)"
            >{{ val.name }}</span
          >
        </template>
      </div>
    </div>
    <div class="params">
      <div class="title">
        {{ PAGELANG.buycount }}
      </div>
      <div class="buy-count">
        <el-input-number
          v-model="buyCount"
          :min="curStock >= 1 ? 1 : curStock"
          :max="curStock"
          @change="handleChange"
        />
        <span class="unit"
          >{{ PAGELANG.unit }} ({{ PAGELANG.stock }}: {{ curStock }})</span
        >
      </div>
    </div>
    <div class="btns">
      <el-button color="#cdaa66" size="large" @click="buyNow">{{
        PAGELANG.buynow
      }}</el-button>
      <el-button color="#e4c994" size="large" @click="addToCart"
        ><i class="iconfont icon-cart"></i>&nbsp;{{
          PAGELANG.addcart
        }}</el-button
      >
    </div>
  </div> -->
</template>

<script>
import { getCurrentInstance } from "vue";
import { closeToast, showLoadingToast, showToast } from "vant";
import { updatePropBoard } from "../util/index.js";

export default {
  name: "GoodsProps",
  props: {
    showAttr: {
      type: Boolean,
      default: false,
    },
    GoodsID: {
      type: Number,
      default: 0,
    },
    showBuyButton: {
      type: Boolean,
      default: true,
    },
    curSelectAttr: {
      type: Array,
      default: new Array(),
    },
    curBuyCount: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      showProp: false,
      defaultThumb: "",
      curThumb: "",
      defaultPrice: "0.00",
      curPrice: "0.00",
      totalStock: 0,
      curStock:0,
      SKUProps: [],
      params: [],
      selectedAttr: [],
      curparams: [],
      buyCount: 0,
      curIndex: -1,
      propboard: [],
	  selectedAttrId:[],
	  skuId:"",
	  DefaultSku:"",//默認sku
	  DefaultSkuProps:"",//默认规格
	  isOffLine:false,
    };
  },
  beforeCreate() {
    const root = getCurrentInstance().appContext.config.globalProperties;
    this.PAGELANG = root.LANG.pages.goods;
    this.CARTLANG = root.LANG.pages.cart;
    this.SYSLANG = root.LANG.system;
    this.UPLOADBASE = root.uploadbase;
    this.root = root;
  },
  watch: {
    showAttr(newVal, oldVal) {
      console.log("showAttr", newVal, oldVal);
      this.showProp = newVal;
	  this.selectedAttrId=[]
      if (newVal) {
        this.getGoodsProps();
      }
    },
  },
  methods: {
	  
	formatPrice(price){
	    return (price / 100).toFixed(2)
	},	  
    onClose() {
      this.$emit("closeProps", false, this.buyCount);
    },
    // 实时读取商品属性
    getGoodsProps() {
      console.log("goodsid", this.GoodsID);

      showLoadingToast({
        message: this.SYSLANG.loading,
        duration: 0,
        forbidClick: true,
      });

      this.buyCount = 1;
      this.root.axios
        .get(this.root.actions.goods_props + this.GoodsID)
        .then((response) => {
          closeToast();
          let { code, msg, data } = response.data;
		  console.log('data',data)
          if (code == "200") {
            this.pdtname = data.productName;
            this.defaultThumb = data.thumb;
            this.curThumb = data.thumb;
            this.defaultPrice = data.minPrice;
            this.curPrice = data.minPrice;
            // this.SKUProps = data.propertyInfo;
            this.selectedAttr = [];
            
            this.selectedAttr = Array(data.propertyInfo.length).fill("");
    
	
	      
          let a=[]
		  
	      data.skus.map(item=>{
			   if(item.isDefault==1){
				  this.DefaultSku=item
				  this.curStock=item.taiWanStock
				  this.selectedAttrId=item.propertyIds.split(",")
				  this.skuId=item.skuId
			   }
			   let b=[]
			   b=item.propertyIds.split(",")
			   b.map(item2=>{
				   a.push(item2)
			   })
		  })
		  
		  console.log('a',a)
		    data.propertyInfo.forEach((property) => {
		      property.values.forEach((value) => {
				  console.log(value)
			      console.log(a.find(item=>item==value.id))
		          if(a.find(item=>item==value.id)==undefined){
					  value.enabled=false
				  }else{
					  value.enabled=true
				  }
		      })
		    })
		  this.SKUProps = data.propertyInfo;  
		   console.log('data.propertyInfo', data.propertyInfo)
		  
		  
	    let values = []
	      data.propertyInfo.forEach((property) => {
	        property.values.forEach((value) => {
	          value.Defaultselected =this.DefaultSku.propertyIds.indexOf(value.id) > -1
	          if (value.Defaultselected) {
	            values.push('' + value.name + '')
	          }
	        })
	      })
	    console.log('values',values)
	    this.selectedAttr=values
	
		console.log(' data.propertyInfo', data.propertyInfo)
		console.log(' data.skus', data.skus)
		console.log('this.selectedAttr',this.selectedAttr)
	
	    this.SKUData = data.skus;
		
		//let arr=["貝殼膚","S"] 
		
		
        this.propboard = updatePropBoard(
              data.propertyInfo,
              data.skus,
              this.selectedAttr
            );
			this.$emit(
			  "selectProps",
			  this.selectedAttr,
			  this.curThumb
		   );
			this.propboard.map(item=>{
				 item.values.map(item2=>{
					  item2.isShow=true
				 })
			})	
			console.log('propboard',this.propboard)
            
			if(this.selectedAttr.length>=2){
				let propertiesId = []
				console.log(' this.propboard', this.propboard)
				 this.selectedAttrId.forEach((e) => {
						let arr = this.isMay(e) || []
						console.log(e, this.isMay(e))
						propertiesId.push(...arr)
				}) 
				this.propertiesId = propertiesId
				console.log('最终propertiesId',this.propertiesId)	
			}
			
			this.canSelectHandle()
			
            
          } else {
            showToast({
              message:
                code == "NOTLOGIN" ? this.SYSLANG[msg] : this.PAGELANG[msg],
              forbidClick: true,
            });
          }
        })
        .catch((error) => {
          console.log("components.GoodsProps.getGoodsProps.error", error);
          closeToast();
          // this.getGoodsProps();
        });
    },
    // 选择属性
    selectOption(pIndex, index) {
      
      if (this.selectedAttr[pIndex] == this.SKUProps[pIndex].values[index].name) {
        this.selectedAttr[pIndex] = "";
      } else {
        this.selectedAttr[pIndex] = this.SKUProps[pIndex].values[index].name;
      }
      this.propboard = updatePropBoard(
        this.SKUProps,
        this.SKUData,
        this.selectedAttr
      );
	  this.$emit(
	    "selectProps",
	    this.selectedAttr,
	    this.curThumb
	  );
	  console.log('this.SKUData',this.SKUData)
	  console.log('this.SKUProps',this.SKUProps)
	  console.log('this.selectedAttr',this.selectedAttr)
	  if(pIndex==0){
		  this.selectedAttrId[0]=this.SKUProps[pIndex].values[index].id
	  }else{
		  this.selectedAttrId[1]=this.SKUProps[pIndex].values[index].id
	  }
	  console.log('this.selectedAttrId',this.selectedAttrId)
	  let isOffLine=true
	  this.SKUData.map((item)=>{
		   let a=this.selectedAttrId.join(",")
		   if(item.propertyIds==a){
			    console.log('item',item)
				this.select=item.stock
				this.skuId=item.skuId
				this.curPrice=item.retailPrice
				this.curStock=item.taiWanStock
				this.curThumb=item.thumb
				isOffLine=false
		   }
	  })
	  this.isOffLine=isOffLine
	  
	  if(this.selectedAttr.length>=2){

		  let propertiesId = []
		  console.log(' this.propboard', this.propboard)
		  this.selectedAttrId.forEach((e) => {
				 let arr = this.isMay(e) || []
				 console.log(e, this.isMay(e))
				 propertiesId.push(...arr)
		 }) 
		 this.propertiesId = propertiesId
		 console.log('最终propertiesId',this.propertiesId)
 
	  }

	 this.canSelectHandle()
     //this.getStockAndPrice();

    },
	
   isMay (e) {
      let arr = []
      this.SKUData
        .filter((item) => item.propertyIds.indexOf(e) > -1)
        .forEach((item) => {
          arr.push(...item.propertyIds.split(',').filter((item) => item != e))
        })
      arr = Array.from(new Set(arr))
      return arr
    },	
	isShow (id) {
	      return this.propertiesId.filter((item) => item == id).length > 0
	        ? true
	        : false
    },
	canSelectHandle () {
		   this.propboard.map(item=>{
		   	 item.values.map(item2=>{
		   		  item2.isShow=true
		   	 })
		   })	
		  let properties = JSON.parse(JSON.stringify(this.propboard))
		  if(this.selectedAttr.length>=2){
			//筛选展示规格
			properties.forEach((property) => {
			  property.values.forEach((value) => {
			    value.isShow = this.isShow(value.name.id)
			     //value.selected=false
			  })
			})
			console.log('properties',properties)  
			  
		  } 
		  this.propboard=properties
   },
	
	
	
    // 计算库存和价格
    getStockAndPrice() {
      let cursku = this.selectedAttr.toString(),
        curindex = -1;
      for (let i = 0; i < this.SKUData.length; i++) {
        let sku = this.SKUData[i].concat([]);
        sku = sku.splice(2, sku.length - 5);
        sku = sku.toString();
        if (sku == cursku) {
          curindex = i;
          break;
        }
      }
      if (curindex != -1) {
        let curparam = this.SKUData[curindex];
        this.curPrice = curparam[curparam.length - 2];
        this.curStock = curparam[curparam.length - 3] * 1;
        if (curparam[1] != "" && curparam[1] != null) {
          this.curThumb = curparam[1];
        } else {
          this.curThumb = this.defaultThumb;
        }
      } else {
        this.curPrice = this.defaultPrice;
        this.curStock = this.totalStock;
        this.curThumb = this.defaultThumb;
      }
      this.curIndex = curindex;
    },
    // 设置属性是否可选
    disabledProp(arr) {
      let cas = this.curAttrs;
      for (let i = 0; i < arr.length; i++) {
        for (let j = 2; j < arr[i].length - 3; j++) {
          for (let k = 0; k < cas[j - 2].options.length; k++) {
            //console.log(cas[j - 2].options[k].name, arr[i][j]);
            if (cas[j - 2].options[k].name == arr[i][j]) {
              cas[j - 2].options[k].disabled = false;
            }
          }
        }
      }
    },
    // 添加到购物车
    addToCart() {
	  if(this.selectedAttr[0].length==0){
		 showToast(this.CARTLANG.没有选择正确的属性);
		 return; 
	  }
      if(this.selectedAttr.length==2){
		if(this.selectedAttr[0].length==0 || this.selectedAttr[1].length==0) {
		  showToast(this.CARTLANG.没有选择正确的属性);
		  return;
		}  
	  }

      if (this.buyCount <= 0) {
        showToast(this.PAGELANG.setbuycount);
        return;
      }

      // console.log(this.SKUData, this.curIndex);
      showLoadingToast({
        message: this.CARTLANG.正在加入购物车,
        duration: 0,
        forbidClick: true,
      });
      this.axios
        .post(this.actions.addSku, {
          // goodsid: this.GoodsID,
          // pdtsku: JSON.stringify(this.SKUData[this.curIndex]),
		  type:"take",
		  skuId:this.skuId,
          quantity: this.buyCount,
        })
        .then(({ data }) => {
          closeToast();
          let { message } = data;
		  if(message=="success"){
			  showToast({
			    message:"添加成功",
			    forbidClick: true,
			  });  
		  }else{
			showToast({
			  message:message,
			  forbidClick: true,
			});  
		  }
        })
        .catch((error) => {
          console.log("components.GoodsProps.addToCart.error", error);
          closeToast();
          //this.addToCart();
        });
    },
    // 立即购买
    buyNow() {
		
	  showLoadingToast({
	  		   message: this.SYSLANG.loading,
	  		   duration: 0,
	  		   forbidClick: true,
	  });	
	  console.log(this.luck2)
	  let dataSkus=[];
	  //let deductionSkus=[];//贈品
	  if(dataSkus.length==0){
	  		  showToast("請選擇商品下單");
	  		  return;
	  }
	  
	  // this.axios
	  //   .post(this.actions.checkOrderCalc, {
	  //          skus:dataSkus,
	  // 		   addressId:"",
	  // 		   deductionSkus:deductionSkus,
	  // 		   luckCharmOrderStatus:this.luckCharmOrderType,
	  // 		   type:"take"
	  //   })
	  //   .then((response) => {
	  //     closeToast();
	  //     let { code, message } = response.data;
	  //     if (code == "200") {
	  // 			this.$router.push({
	  // 			  name: "placeorder",
	  // 			  state: {
	  // 			    skus:JSON.stringify(dataSkus),
	  // 				deductionSkus:deductionSkus,
	  // 				luckCharmOrderStatus:this.luckCharmOrderType,
	  // 			    isfromcart: true,
	  // 			  },
	  // 			});
	  //     } else {
	  //       showToast(
	  //         code != 200 ? message : message
	  //       );
	  //     }
	  // })	
		
		
		
      if (this.SKUData.length > 0 && this.curIndex < 0) {
        showToast(this.CARTLANG.没有选择正确的属性);
        return;
      }

      if (this.buyCount < 10 || this.buyCount % 10 > 0) {
        showToast(this.PAGELANG.setbuycount);
        return;
      }

      let postdata = [];
      postdata[0] = new Object();
      postdata[0].goodsid = this.GoodsID;
      postdata[0].pdtsku = this.curIndex;
      postdata[0].buycount = this.buyCount;
      postdata[0].pdtname = this.pdtname;
      postdata[0].pdtthumb = this.curThumb;
      postdata[0].selpropstext = this.selectedAttr.toString();

      console.log("detail postdata", postdata);

      this.$router.push({
        name: "placeorder",
        state: {
          orderdata: JSON.stringify(postdata),
        },
      });
    },
  },
};
</script>

<style scoped>
@media (max-width: 678px) {
  .pc-props {
    display: none;
  }
  .goods-attrs-popup {
    overflow: initial;
  }

  .goods-attrs {
    padding: var(--van-popup-close-icon-margin);
    padding-bottom: 0;
    --aog-border-color: #e4e4e4;
  }

  .goods-attrs .info {
    position: relative;
    width: 100%;
    height: 22vw;
    padding-left: calc(30vw + 20px);
    box-sizing: border-box;
    border-bottom: 1px solid var(--aog-border-color);
  }
  .goods-attrs .info .thumb {
    width: 30vw;
    height: 30vw;
    position: absolute;
    left: 0;
    bottom: var(--van-popup-close-icon-margin);
    padding: 2px;
    border: 1px solid #e4e4e4;
    background-color: #fff;
  }

  .goods-attrs .info .cur-attrs {
    display: flex;
    flex-direction: column;
    justify-content: center;
    line-height: 24px;
    font-size: 14px;
    color: #333;
  }

  .goods-attrs .info .cur-attrs .price {
    font-size: 18px;
    color: var(--van-card-price-color);
  }

  .goods-attrs .info .cur-attrs .stock {
    font-size: 14px;
    color: #777;
  }

  .goods-attrs .params {
    padding: var(--van-popup-close-icon-margin) 0;
    border-bottom: 1px solid var(--aog-border-color);
  }
  .goods-attrs .params .title {
    font-size: 16px;
    font-weight: bold;
    color: #333;
  }

  .goods-attrs .params .options {
    display: flex;
    flex-wrap: wrap;
  }

  .goods-attrs .params .options .option {
    display: inline-block;
    padding: 0 10px;
    line-height: 30px;
    font-size: 14px;
    background-color: #e6e6e6;
    border-radius: 6px;
    margin-top: calc(var(--van-popup-close-icon-margin) / 2);
    margin-right: var(--van-popup-close-icon-margin);
    box-sizing: border-box;
    color: #333;
  }
  .goods-attrs .params .options .option.active {
    color: #fff;
    background-color: var(--van-card-price-color);
  }
  .goods-attrs .params .options .option.disabled {
    color: #999;
  }

  .goods-attrs .buy-count {
    display: flex;
    height: 50px;
    font-size: 16px;
    font-weight: bold;
    justify-content: space-between;
    align-items: center;
    --van-stepper-button-round-theme-color: var(--van-card-price-color);
  }

  .btns {
    --props-btns-height: 60px;
    display: flex;
    height: var(--props-btns-height);
  }

  .btns .btn {
    flex-grow: 1;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    font-size: 20px;
    color: #fff;
  }
  .btns .btn.add-cart {
    background-color: #e4c994;
  }
  .btns .btn.buy-now {
    background-color: #cdaa66;
  }
}

@media (min-width: 679px) {
  .m-props {
    display: none;
  }
  .pc-props .params {
    display: flex;
    width: 100%;
    min-height: calc(100vw / 1920 * 40);
    margin-bottom: calc(100vw / 1920 * 10);
    line-height: calc(100vw / 1920 * 40);
    --el-color-primary: var(--van-card-price-color);
  }
  .pc-props .params .title {
    width: calc(100vw / 1920 * 100);
    font-size: calc(100vw / 1920 * 14);
  }
  .pc-props .params .price {
    color: #fe3e57;
    font-size: calc(100vw / 1920 * 26);
  }
  .pc-props .params .price::before {
    content: var(--aog-currency);
    font-size: 70%;
  }
  .pc-props .params .attrs {
    line-height: 0;
  }
  .pc-props .params .attrs .option {
    display: inline-block;
    padding: 0 16px;
    border: 1px solid var(--el-input-border-color, var(--el-border-color));
    background-color: #fff;
    line-height: calc(100vw / 1920 * 30);
    font-size: calc(100vw / 1920 * 14);
    margin-top: calc(100vw / 1920 * 5);
    margin-right: calc(100vw / 1920 * 10);
    border-radius: var(--el-border-radius-base);
    cursor: pointer;
    box-sizing: border-box;
  }
  .pc-props .params .attrs .option.active {
    color: #fff;
    background-color: var(--van-card-price-color);
    border-color: var(--van-card-price-color);
  }
  .pc-props .params .thumb {
    width: 34px;
    height: 34px;
    margin-right: calc(100vw / 1920 * 10);
    margin-bottom: calc(100vw / 1920 * 10);
    padding: 2px;
    border: 1px solid #ddd;
    background-color: #fff;
    cursor: pointer;
  }
  .pc-props .params .thumb.active {
    border-color: #000;
  }
  .pc-props .params .buy-count .unit {
    font-size: calc(100vw / 1920 * 12);
    margin-left: 8px;
  }
  .pc-props .btns {
    padding-top: calc(100vw / 1920 * 40);
  }
}
</style>